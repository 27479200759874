<div class="star-rating-container">
  <span *ngIf="checkZeroString !== 0">
    <span class="ratings-value">{{ ratingValue }}</span>
    <span class="material-icons-outlined">
      {{ ratingValue | starRating: ratingValue }}
    </span>
    <span (click)="isCommentsClick(true)" class="ratings-value provider-ratings-count" aria-label="View provider ratings">
      <span>(</span>
      <span class="rating-number">{{ ratingCount }}</span>
      <span>)</span>
    </span>
  </span>

  <span *ngIf="commentCount !== 0">
    <span class="comments-spacing"></span>
    <span class="comment-value">Comments</span>
    <span (click)="isCommentsClick(true)" class="comment-value comment-count" aria-label="View comments">
      <span>(</span>
      <span class="comment-number">{{ commentCount }}</span>
      <span>)</span>
    </span>
  </span>
</div>
