/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FadProviderDetailRequest,
  FadProviderDetailService,
  FadSeachStateService,
} from '@dmx-fad-ui/fad/data-access';
import { Subscription } from 'rxjs';

import { LocationService } from '@dmx-fad-ui/fad/services';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'atlas-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.scss'],
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class PrintPageComponent implements OnInit {
  providerProfileName: string = '';
  providerDetailSub: Subscription;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  providerDetail: any = {};
  providerLocations: Array<string>;
  bio: string = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  providerExpertise: any;
  providerExpertiseColumnOne: string[] = [];
  providerExpertiseColumnTwo: string[] = [];
  schools: string[] = [];
  visitTypes: string[] = [];
  boards: string[] = [];
  residencies: string[] = [];
  signatureSpecialty: string = '';
  positions: string[] = [];
  fellowships: string[] = [];

  hasData: boolean = false;
  spinnerSub: Subscription;
  isSpinning: boolean = false;
  hasPrinted: boolean = false;

  isBioLoaded: boolean = false;
  isExpertiseLoaded: boolean = false;
  expertiseCountLoaded: number = 0;
  isLocationLoaded: boolean = false;
  isSchoolsLoaded: boolean = false;
  isBoardsLoaded: boolean = false;
  isResidencyLoaded: boolean = false;
  isImageLoaded: boolean = false;
  isPositionsLoaded: boolean = false;
  isFellowshipsLoaded: boolean = false;
  isNewPrintPage: boolean = environment.kyruusFeatures.printPage.isNewPrintPage;

  //QR Code Setup
  elementType = 'url';
  correctionLevel = 'H';
  qrCodeUrl: string = '';

  constructor(
    private fadProvDetServ: FadProviderDetailService,
    private fadSearchStateServ: FadSeachStateService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
    private locationServ: LocationService,
  ) {
    (this.fadProvDetServ = fadProvDetServ),
      (this.fadSearchStateServ = fadSearchStateServ),
      (this.activatedRoute = activatedRoute);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.spinnerSub = this.fadSearchStateServ.isShowSpinnerSubj.subscribe(
        (isSpinning) => {
          this.isSpinning = isSpinning;
        },
        (error) => {
          console.log(error);
        },
      );
      const hideFooter = document.getElementById('footer');
      hideFooter.style.display = 'none';
      const hideHeader = document.getElementById('header');
      hideHeader.style.display = 'none';
      this.providerProfileName = this.activatedRoute.snapshot.params['providername'];
      this.providerDetailSub = this.fadProvDetServ.providerDetailSubj.subscribe((data) => {
        this.providerDetail = data.payload.providerDetail;
        this.providerLocations = this.getLocationResults(this.providerDetail.locations);
        this.bio = this.providerDetail.bio;
        this.providerExpertise = this.providerDetail.conditions;
        this.splitProvidersIntoColumns(this.providerExpertise);
        this.signatureSpecialty = this.providerDetail.signatureSpecialty;
        //credentials
        this.schools = this.providerDetail.schools;
        this.visitTypes = this.providerDetail.visitTypes;
        this.residencies = this.providerDetail.residencies;
        this.boards = this.providerDetail.boards;
        this.positions = this.providerDetail.jobTitles;
        this.fellowships = this.providerDetail.fellowships;
        this.hasData = true;
        if (environment.qrcode) {
          this.qrCodeUrl = window.location.origin + '/' + this.providerProfileName;
        }
        if (this.providerDetail.printImageUrl.includes('AHN-provider-not-found')) {
          this.providerDetail.printImageUrl =
            '../../../../../assets/AHN-provider-not-found-print.png';
        }
      });
      const provDetReq: FadProviderDetailRequest =
        this.fadProvDetServ.createFadProviderDetailRequestPrint(this.providerProfileName);
      this.fadProvDetServ.getProviderDetail(provDetReq);
    }
  }

  splitProvidersIntoColumns(providerExpertiseInput) {
    providerExpertiseInput.forEach((_exp, i) => {
      const expertiseCountHalf = providerExpertiseInput.length / 2;

      if (i < expertiseCountHalf) {
        this.providerExpertiseColumnOne.push(providerExpertiseInput[i]);
      } else {
        this.providerExpertiseColumnTwo.push(providerExpertiseInput[i]);
      }
    });
  }

  getLocationResults(locationResultsArray) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const locations: Array<any> = [];
    const pracBuildings = locationResultsArray;
    pracBuildings.forEach((item) => {
      const providerPracticeBuildingObj =
        this.locationServ.transformPracticeBuildingStringToObject(item);
      locations.push(providerPracticeBuildingObj);
    });
    return locations;
  }

  getProviderPracticeBuildingByKey(building: Array<string>, buildingAttribute: string) {
    const result = building.find((item) => item.includes(buildingAttribute));
    if (result) {
      const value = result.split('=')[1];
      return value == 'null' ? '' : value;
    }
    return '';
  }

  onReceivedLocationContent($event) {
    this.isLocationLoaded = true;
    this.printIfReady();
  }

  onReceivedExpertiseContent($event) {
    this.expertiseCountLoaded++;
    if (this.expertiseCountLoaded >= this.providerExpertise.length) {
      this.isExpertiseLoaded = true;
      this.printIfReady();
    } else {
      this.isExpertiseLoaded = false;
    }
  }

  onReceivedBioContent($event) {
    this.isBioLoaded = true;
    this.printIfReady();
  }

  onReceivedSchoolsContent($event) {
    this.isSchoolsLoaded = true;
    this.printIfReady();
  }

  onReceivedFellowshipsContent($event) {
    this.isFellowshipsLoaded = true;
    this.printIfReady();
  }

  onReceivedPositionsContent($event) {
    this.isPositionsLoaded = true;
    this.printIfReady();
  }

  onReceivedBoardsContent($event) {
    this.isBoardsLoaded = true;
    this.printIfReady();
  }

  onReceivedResidencyContent($event) {
    this.isResidencyLoaded = true;
    this.printIfReady();
  }

  onReceivedProviderImageContent($event) {
    this.isImageLoaded = true;
    this.printIfReady();
  }

  checkCompleteLoading() {
    if (this.boards.length === 0) {
      this.isBoardsLoaded = true;
    }

    if (this.residencies.length === 0) {
      this.isResidencyLoaded = true;
    }

    if (this.bio === '<p></p>') {
      this.isBioLoaded = true;
    }

    if (this.providerExpertise.length === 0) {
      this.isExpertiseLoaded = true;
    }

    if (this.fellowships.length === 0) {
      this.isFellowshipsLoaded = true;
    }

    if (this.positions.length === 0) {
      this.isPositionsLoaded = true;
    }

    if (
      this.isLocationLoaded === true &&
      this.isExpertiseLoaded === true &&
      this.isBioLoaded === true &&
      this.isSchoolsLoaded === true &&
      this.isBoardsLoaded === true &&
      this.isResidencyLoaded === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkForSpinner() {
    const hasSpinner = document.getElementById('spinnerElement');
    if (hasSpinner === null) {
      return false;
    } else {
      return true;
    }
  }

  async printIfReady() {
    if (isPlatformBrowser(this.platformId)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const hasSpinner = this.checkForSpinner();
    }
    // OLD APPROACH FOR PRINT - RETAIN FOR REFERENCE
    // if (hasSpinner === true) {
    // } else {
    //   let isLoadingComplete: boolean = this.checkCompleteLoading();
    //   if (isLoadingComplete === true && this.hasPrinted === false) {
    //     window.print();
    //     this.hasPrinted = true;
    //     setTimeout(() => {
    //       window.close();
    //     }, 2000);
    //   }
    // }
  }

  hasExpertise(): boolean {
    if (this.expertiseCountLoaded > 0) {
      return true;
    } else {
      return false;
    }
  }
}
