export const environment = {
  production: false,
  gMapsApiKey: 'AIzaSyBN4U9JlSSZ0cFL-UFF8ormapEWtpzC2co',
  xApiKey: 'AIzaSyD9eT-VBvQks0rrUiHIYvIgkV8KrknuMSA',
  xPubApiKey: 'AIzaSyCHGlVtlQhORiQQB1rZvh5nfGqlIBaptSM',
  searchEndpoint: 'https://dev.dmxapi.highmark.com/ahnfad/v1/search',
  suggestEndpoint: 'https://dev.dmxapi.highmark.com/ahnfad/v1/suggest',
  emailEndpoint: 'https://dev.dmxapi.highmark.com/ahnfad/v1/email/send',
  providerDetailEndpoint: 'https://dev.dmxapi.highmark.com/ahnfad/v1/get-provider-detail',
  physicianIndexEndpoint: 'https://dev.dmxapi.highmark.com/ahnfad/v1/provider-directory',
  providerCommentEndpoint: 'https://dev.dmxapi.highmark.com/ahnfad/v1/get-provider-comments',
  pdfRenderUrlEndpoint: 'https://dev.dmxapi.highmark.com/ahnfad/v1/pdf-generator/render-url',
  pdfProviderUrlPath: 'http://dev.findcare.ahn.org/print/',
  searchEndpointWithLocations:
    'https://dmx-fad-search-api-development-roman-ai5mdaxlxa-uk.a.run.app/ahnfad/v1/search',
  locationDetailEndpoint: 'https://dev.dmxapi.highmark.com/ahnfad/v1/get-location-detail',
  relatedProvidersEndpoint: 'https://dev.dmxapi.highmark.com/ahnfad/v1/get-related-providers',
  docasap: false,
  qrcode: true,
  compares: true,
  docasapBase: 'https://docasap.com/white-label/org_code/AHN/specialty_id/0/key_reason/-1/key_npi/',
  docasapTrail: '/key_level/3/key_type/LINK/key_partner_code/AHN',
  debugTools: {
    showSearchResultIndexNumbers: false,
  },
  commentFilterOn: true,
  referralsOn: true,
  newShareFeaturesOn: false,
  shareButtonPath: 'http://dev.findcare.ahn.org',
  recaptcha: {
    siteKey: '6Lc1T3QmAAAAALgVcFt9DykvAzE9mc3Aa5VHgumE',
  },
  videoFilterEnabled: true,
  dtSource: `https://js-cdn.dynatrace.com/jstag/17ec423115e/bf21734fbt/9e30eb0d79e3b680_complete.js`,
  ribbonText: 'Dev',
  isTabProviderPageEnabled: true,
  myChartProdEndpoint: 'https://mychart.ahn.org/MyChart/openscheduling/standalone?id=',
  myChartSchedulingEnabled: true,
  disableAlphaSort: true,
  kyruusFeatures: {
    resultsPage: {
      isNewFilter: false,
      isNewProviderCard: false
    },
    providerPage: {
      isNewProfileBanner: false,
      isNewAboutTab: false,
      isNewLocationTab: false,
      isNewReviewsTab: false,
      isNewReferralTab: false,
    },
    printPage: {
      isNewPrintPage: false
    },
    comparePage: {
      isNewComparePage: false
    }
  }
};
