<!-- feature flag for New provider profile page -->
<div *ngIf="isNewproviderProfileExperience" class="provider-detail-container">
  <div class="provider-detail-new-experience">
    <div class="provider-margin provider-top">
      <section class="provider-detail-profile">
        <div *ngIf="this.isNewProviderBanner">
          <engen-profile-banner-v2>New Profile Banner</engen-profile-banner-v2>
        </div>
        <div *ngIf="!this.isNewProviderBanner">
          <atlas-provider-profile
          [providerProfileDetails]="this.providerDetails"
          [providerPracticeBuildings]="this.providerLocations"
        ></atlas-provider-profile>
        </div>
      </section>
      <div class="new-material-tab-experience">
        <section id="tabnav">
          <mat-tab-group [(selectedIndex)]="selectedTabindex" animationDuration="0ms">
            <mat-tab label="About">
            <div *ngIf="this.isNewAboutTab">New About Tab Here</div>
            <div *ngIf="!this.isNewAboutTab">
              <atlas-provider-bio
                *ngIf="checkProviderBio"
                [bio]="this.bio"
                [youTubeVideoBioUrl]="this.youTubeVideoBioUrl"
                [providerLanguageResults]="this.providerLanguageResults"
                [displayName]="this.displayName"
                [videoFilterEnabled]="this.videoFilterEnabled"
              ></atlas-provider-bio>
              <atlas-provider-expertise
                [providerExpertiseResults]="this.providerExpertiseResults"
              ></atlas-provider-expertise>
              <atlas-provider-credentials
                [schoolsResult]="this.schools"
                [residenciesResult]="this.residencies"
                [jobTitlesResult]="this.jobTitles"
                [boardsResult]="this.boards"
                [fellowshipsResult]="fellowships"
              ></atlas-provider-credentials>
              <atlas-provider-lgbt *ngIf="lgbtqAligned"></atlas-provider-lgbt>
              <atlas-provider-publications
                [pubMedLinkUrl]="this.pubMedLinkUrl"
              ></atlas-provider-publications>
              <atlas-provider-videodisclaimer
                *ngIf="youTubeVideoBioUrl && videoFilterEnabled"
              ></atlas-provider-videodisclaimer>
              </div>
            </mat-tab>
          
            <mat-tab label="Location">
              <div *ngIf="this.isNewLocationTab">New Location Tab Here</div>
              <div *ngIf="!this.isNewAboutTab">
              <section class="provider-detail-map">
                <atlas-provider-map
                  *ngIf="isBrowser()"
                  [mapHeight]="this.mapHeight"
                  [providerResult]="this.providerLocations"
                ></atlas-provider-map>
                <atlas-provider-locations
                  class="provider-locations"
                  [providerPracticeBuildings]="this.providerLocations"
                ></atlas-provider-locations>
              </section>
            </div>
            </mat-tab>
            <div *ngIf="this.showReview">
              <mat-tab label="Reviews">
                <div *ngIf="this.isNewReviewsTab">New Review Tab Here</div>
                <div *ngIf="!this.isNewReviewsTab">
                <atlas-provider-ratings-comments
                  class="provider-reviews"
                  [providerDetails]="providerDetails"
                  [providerName]="this.providerName"
                ></atlas-provider-ratings-comments>
                </div>
              </mat-tab>
            </div>
            <div *ngIf="this.showReferral">
              <mat-tab label="Refer Your Patient">
                <div *ngIf="this.isNewReferralTab">New Referral Tab Here</div>
                <div *ngIf="!this.isNewReferralTab">
                <section *ngIf="hasData" class="new-provider-detail-referral-container">
                  <div *ngIf="this.showReferral" class="new-provider-detail-referral">
                    <atlas-referral-button
                      *ngIf="this.referralsOn"
                      [providerLocations]="this.providerPracticeBuildings"
                      [provider]="this.providerDetails"
                    ></atlas-referral-button>
                  </div>
                </section>
              </div>

              </mat-tab>
            </div>
          </mat-tab-group>
        </section>
      </div>
    </div>
  </div>
</div>

<!-- feature flag for old provider profile page.
 Remove this code once once we go live with new version. -->
<div *ngIf="!isNewproviderProfileExperience" class="provider-detail-container">
  <div class="provider-detail-old-experience">
    <div class="provider-margin provider-top">
      <section class="provider-detail-profile">
        <atlas-provider-profile
          [providerProfileDetails]="this.providerDetails"
          [providerPracticeBuildings]="this.providerLocations"
        ></atlas-provider-profile>
      </section>
      <div class="provider-top-right">
        <section class="provider-detail-map">
          <atlas-provider-map
            *ngIf="isBrowser()"
            [mapHeight]="this.mapHeight"
            [providerResult]="this.providerLocations"
          ></atlas-provider-map>
        </section>
        <section *ngIf="hasData" class="provider-detail-referral">
          <div *ngIf="this.showReferral">
            <atlas-referral-button
              *ngIf="this.referralsOn"
              [providerLocations]="this.providerPracticeBuildings"
              [provider]="this.providerDetails"
            ></atlas-referral-button>
          </div>
        </section>
      </div>
    </div>
    <div class="provider-margin">
      <div *ngIf="hasData" class="provider-detail-info">
        <atlas-provider-locations
          [providerPracticeBuildings]="this.providerLocations"
        ></atlas-provider-locations>
        <atlas-provider-bio
          *ngIf="checkProviderBio"
          [bio]="this.bio"
          [youTubeVideoBioUrl]="this.youTubeVideoBioUrl"
          [providerLanguageResults]="this.providerLanguageResults"
          [displayName]="this.displayName"
          [videoFilterEnabled]="this.videoFilterEnabled"
        ></atlas-provider-bio>
        <atlas-provider-expertise
          [providerExpertiseResults]="this.providerExpertiseResults"
        ></atlas-provider-expertise>
        <atlas-provider-ratings-comments
          [providerDetails]="providerDetails"
          [providerName]="this.providerName"
        ></atlas-provider-ratings-comments>
        <atlas-provider-credentials
          [schoolsResult]="this.schools"
          [residenciesResult]="this.residencies"
          [jobTitlesResult]="this.jobTitles"
          [boardsResult]="this.boards"
          [fellowshipsResult]="fellowships"
        ></atlas-provider-credentials>
        <atlas-provider-lgbt *ngIf="lgbtqAligned"></atlas-provider-lgbt>
        <atlas-provider-publications
          [pubMedLinkUrl]="this.pubMedLinkUrl"
        ></atlas-provider-publications>
        <atlas-provider-videodisclaimer
          *ngIf="youTubeVideoBioUrl && videoFilterEnabled"
        ></atlas-provider-videodisclaimer>
      </div>
    </div>
  </div>
</div>
